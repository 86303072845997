<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block key="CUSTOMER" v-if="step === 'CUSTOMER'">
    <template v-slot:default>
      <form @submit.prevent="nextStep()" id="customerform" class="form">
        <h3>Auto</h3>
        <div class="two-columns">
          <div class="input-label">Kenteken</div>
          <input type="text" name="licence-plate" v-model="licensePlate"
                 maxlength="16" pattern="^[a-zA-Z0-9-]+$"
                 placeholder="Kenteken"
                 required/>
        </div>

        <h3>Gegevens klant</h3>
        <input type="text" name="firstName" v-model="firstName"
               maxlength="128"
               placeholder="Voornaam"
               required/>
        <input type="text" name="lastName" v-model="lastName"
               maxlength="128"
               placeholder="Achternaam"
               required/>
        <input type="email" name="email" v-model="email"
               maxlength="255"
               placeholder="Emailadres"
               required/>
        <input type="phone" name="phone" v-model="phone"
               maxlength="64" pattern="^\+?[0-9 -]+$"
               placeholder="Telefoonnummer"
               required/>
        <input v-if="leaseContext == 'business'"
               type="text" name="kvkNumber" v-model="kvkNumber"
               maxlength="64" pattern="^[0-9]+$"
               placeholder="KVK-nummer"
               required>

      </form>
    </template>
    <template v-slot:actions>
      <button form="customerform" class="content__actions__action">Volgende</button>
    </template>
  </content-block>
  <content-block key="CALC1" v-else-if="step === 'CALC1'">
    <template v-slot:default>
      <form @submit.prevent="nextStep()" id="carform" class="form">
        <h3>Prijs</h3>

        <div class="two-columns">
          <div class="input-label">Verkoopprijs <template v-if="leaseContext === 'business'"><br>excl.&nbsp;BTW</template></div>
          <input type="number" name="price" v-model="price"
                 min="200" max="9999999"
                 placeholder="Verkoopprijs"
                 @change="updateCalculationFromPrice()"
                 required/>
        </div>

        <h3>
          Aanbetaling
          <loading-indicator type="inline" v-show="false && calculatorState === 'updating' && initial === ''" />
        </h3>

        <div class="two-columns">
          <div class="input-label">Inruilbedrag</div>
          <input :disabled="price <= 0"
                 type="number" name="tradein" v-model="tradein" ref="tradein"
                 min="0" :max="initialMax"
                 placeholder="Inruilbedrag"
                 @change="updateCalculation()"/>
        </div>

        <div class="two-columns">
          <div class="input-label">Aanbetaling</div>
          <input :disabled="price <= 0"
                 type="number" name="extraInitial" v-model="extraInitial" ref="extraInitial"
                 :min="extraInitialMin" :max="extraInitialMax"
                 placeholder="Aanbetaling"
                 @change="updateCalculation()"/>
        </div>

        <div class="two-columns" v-show="calculatorState !== 'none'">
          <div class="input-label">Totale aanbetaling</div>
          <div class="simple-value" v-if="calculatorState === 'done'">{{ formattedPrice(initial) }}</div>
          <div class="simple-value" v-else><loading-indicator type="inline" /></div>
        </div>

      </form>
    </template>
    <template v-slot:actions>
      <button form="carform" class="content__actions__action">Volgende</button>
    </template>
  </content-block>
  <content-block key="CALCULATORLOADING" v-else-if="step === 'CALC2' && calculatorState === 'loading'">
    <loading-indicator/>
  </content-block>
  <content-block key="CALCULATORLOADING" v-else-if="step === 'CALC2' && calculatorState === 'failed'">
    <error-indicator message="Er is iets misgegaan bij het ophalen van de berekening. Probeer het opnieuw."/>
  </content-block>
  <content-block key="CALC2" v-else-if="step === 'CALC2' && calculatorState !== 'loading'">
    <template v-slot:default>
      <form @submit.prevent="nextStep()" id="leaseform" class="form">
        <h3>Bereken het maandbedrag</h3>
        <div class="two-columns">
          <div class="input-label">Leenbedrag</div>
          <div class="simple-value">{{ formattedPrice(loanValue) }}</div>
        </div>
        <div v-show="leaseContext === 'business'" class="two-columns">
          <div class="input-label">Slottermijn</div>
          <input type="number" name="residual" v-model="residual"
                 :min="residualMin" :max="residualMax"
                 placeholder="Slottermijn"
                 @change="updateCalculation()"
                 required/>
        </div>
        <template v-if="calculatorState === 'done'">
          <div class="notice">Selecteer een looptijd</div>
          <div v-for="option in installmentsOptions" class="two-columns">
            <span class="months-text">
              {{ option.months }} maanden
            </span>
            <button class="months-button"
                    @click="installments = option.months; monthlyPrice = option.price">
              {{ formattedPrice(option.price) }}
            </button>
          </div>
          <div class="notice">Let op, deze prijzen zijn een indicatie.</div>
        </template>
        <loading-indicator v-else/>
      </form>
    </template>
  </content-block>
  <content-block key="CONFIRM" v-else-if="step === 'CONFIRM'">
    <template v-slot:default>
      <h3>Bevestig de ingevulde gegevens</h3>
      <div class="summary">
        <h4 class="summary__title">De klant</h4>
        <div class="summary__spec">
          <div class="summary__spec__name">Voornaam</div>
          <div class="summary__spec__value">{{ firstName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Achternaam</div>
          <div class="summary__spec__value">{{ lastName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">E-mailadres</div>
          <div class="summary__spec__value">{{ email }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Telefoonnummer</div>
          <div class="summary__spec__value">{{ phone }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">KVK-nummer</div>
          <div class="summary__spec__value">{{ kvkNumber }}</div>
        </div>
      </div>
      <div class="summary">
        <h4 class="summary__title">Het voertuig</h4>
        <div class="summary__spec">
          <div class="summary__spec__name">Kenteken</div>
          <div class="summary__spec__value">{{ licensePlate }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Verkoopprijs</div>
          <div class="summary__spec__value">{{ formattedPrice(price) }}</div>
        </div>
      </div>
      <div class="summary">
        <h4 class="summary__title">De lening</h4>
        <div v-if="initial > 0" class="summary__spec">
          <div class="summary__spec__name">Aanbetaling</div>
          <div class="summary__spec__value">{{ formattedPrice(initial) }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Leenbedrag</div>
          <div class="summary__spec__value">{{ formattedPrice(loanValue) }}</div>
        </div>
        <div v-if="residual > 0" class="summary__spec">
          <div class="summary__spec__name">Slottermijn</div>
          <div class="summary__spec__value">{{ formattedPrice(residual) }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Betaaltermijn</div>
          <div class="summary__spec__value">{{ installments }} maanden</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Maandbedrag</div>
          <div class="summary__spec__value">{{ formattedPrice(monthlyPrice) }}</div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <button :disabled="sending || requesting"
              @click.prevent="sendCalculation()"
              class="content__actions__action">
        <loading-indicator type="inline" v-if="sending" /> Berekening verzenden
      </button>
      <button :disabled="sending || requesting"
              @click.prevent="sendRequest()"
              class="content__actions__action">
        <loading-indicator type="inline" v-if="requesting" /> Aanvraag verzenden
      </button>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'
import { formattedPrice } from '@/util'

export default{
    props: ['prefill'],
    data: () => ({
        step: "CALC1",
        calculatorState: 'none',
        sending: false,
        requesting: false,
        // Customer information
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        kvkNumber: '',
        // Car information
        licensePlate: '',
        price: '',
        // Lease calculation
        leaseContext: 'business',
        tradein: '',
        extraInitial: '',
        extraInitialMin: '',
        extraInitialMax: '',
        // Note that we don't send initial to the server, but the sum of
        // tradein and extraInitial. We then set those values based on what
        // comes back for initial.
        initial: '',
        initialMin: '',
        initialMax: '',
        residual: '',
        residualMin: '',
        residualMax: '',
        installments: '',
        installmentsOptions: '',
        loanValue: '',
        monthlyPrice: '',
    }),
    computed: mapGetters([
        'session'
    ]),
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    methods: {
        onClickBackButton() {
            if (this.step === "CALC1") {
                this.$router.push({ name: 'dashboard' })
            } else if (this.step === "CALC2") {
                this.step = "CALC1"
            } else if (this.step === "CUSTOMER") {
                this.step = "CALC2"
            } else if (this.step === "CONFIRM") {
                this.step = "CUSTOMER"
            }
        },
        nextStep() {
            if (this.step === "CALC1") {
                this.calculatorState = 'loading'
                this.resetCalculator()
                this.step = "CALC2"

                Api.getLeaseCalculation(this.session.token, this.getCalculatorInputs())
                    .then(data => {
                        this.updateCalculatorValues(data.calculation)
                        this.calculatorState = 'done'
                    })
                    .catch(() => this.calculatorState = 'failed')
            } else if (this.step === "CALC2") {
                this.step = "CUSTOMER"
            } else if (this.step === "CUSTOMER") {
                this.step = "CONFIRM"
            }
        },
        updateCalculation() {
            this.calculatorState = 'updating'
            Api.getLeaseCalculation(this.session.token, this.getCalculatorInputs())
                .then(data => {
                    this.updateCalculatorValues(data.calculation)
                    this.calculatorState = 'done'
                })
                .catch(() => this.calculatorState = 'failed')
        },
        updateCalculationFromPrice() {
            this.resetCalculator()
            this.updateCalculation()
        },
        updateCalculatorValues(calculation) {
            this.price = calculation.price
            this.initialMin = calculation.initialBounds[0]
            this.initialMax = calculation.initialBounds[1]
            this.initial = calculation.initial

            this.tradein = Math.min(this.initial, this.tradein) || ''
            this.extraInitial = (this.initial - this.tradein) || ''

            this.extraInitialMin = Math.max(0, this.initialMin - (this.tradein || 0))
            this.extraInitialMax = this.initialMax - (this.tradein || 0)

            this.loanValue = calculation.loanValue
            this.residualMin = calculation.residualBounds[0]
            this.residualMax = calculation.residualBounds[1]
            this.residual = calculation.residual
            this.installmentsOptions = calculation.installmentsOptions
            this.installments = calculation.installments
            this.monthlyPrice = calculation.monthlyPrice
        },
        resetCalculator() {
            // Note that this does not clear extraInitial and tradein, which
            // are configured in the previous step. The value for initial that
            // we send to the server is the combination of those two, *not* the
            // value of initial.
            this.initial = ''
            this.initialMin = ''
            this.initialMax = ''
            this.residual = ''
            this.residualMin = ''
            this.residualMax = ''
            this.installments = ''
            this.installmentsOptions = ''
            this.loanValue = ''
            this.monthlyPrice = ''
        },
        getCalculatorInputs() {
            return {
                context: this.leaseContext,
                price: this.price,
                initial: parseInt(this.extraInitial || 0) + parseInt(this.tradein || 0),
                residual: this.residual === '' ? null : this.residual,
                installments: this.installments === '' ? null : this.installments,
            }
        },
        formattedPrice,
        sendCalculation() {
            this.sending = true

            const payload = {
                car: {
                    licensePlate: this.licensePlate,
                    price: this.price
                },
                customer: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phoneNumber: this.phone,
                    kvkNumber: (this.leaseContext === 'business' ? this.kvkNumber : undefined)
                },
                calculation: {
                    context: this.leaseContext,
                    initial: this.initial === '' ? null : this.initial,
                    loanValue: this.loanValue === '' ? null : this.loanValue,
                    residual: this.residual === '' ? null : this.residual,
                    installments: this.installments === '' ? null : this.installments,
                    monthlyPrice: this.monthlyPrice === '' ? null : this.monthlyPrice
                }
            }

            Api.sendLeaseCalculation(this.session.token, payload)
                .then(data => this.$router.push({ name: 'dashboard' }))
                .catch(() => {
                    this.sending = false
                    window.alert('Er is iets foutgegaan bij het verzenden van de berekening. Probeer het opnieuw.')
                })
        },
        sendRequest() {
            this.requesting = true

            const payload = {
                car: {
                    licensePlate: this.licensePlate,
                    price: this.price
                },
                customer: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phoneNumber: this.phone,
                    kvkNumber: (this.leaseContext === 'business' ? this.kvkNumber : undefined)
                },
                calculation: {
                    context: this.leaseContext,
                    initial: this.initial === '' ? null : this.initial,
                    loanValue: this.loanValue === '' ? null : this.loanValue,
                    residual: this.residual === '' ? null : this.residual,
                    installments: this.installments === '' ? null : this.installments,
                    monthlyPrice: this.monthlyPrice === '' ? null : this.monthlyPrice
                }
            }

            Api.sendLeaseRequest(this.session.token, payload)
                .then(data => this.$router.push({ name: 'dashboard' }))
                .catch(() => {
                    this.requesting = false
                    window.alert('Er is iets foutgegaan bij het verzenden van de aanvraag. Probeer het opnieuw.')
                })
        }
    },
    mounted() {
        if (this.prefill) {
            this.firstName = this.prefill.firstName || this.firstName
            this.lastName = this.prefill.lastName || this.lastName
            this.email = this.prefill.email || this.email
            this.phone = this.prefill.phone || this.phone
            this.licensePlate = this.prefill.licensePlate || this.licensePlate
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.two-columns {
    display: flex;
    align-items: center;
    color: $c-white;
    margin-bottom: 10px;

    > * {
        flex: 1;
        margin-bottom: 0;
        max-width: 50%;
    }
}

.simple-value {
    font-weight: bold;
}

.notice {
    color: $c-white;
    margin: 0 0 5px;
    font-weight: bold;
}

.months-button {
    position: relative;
    padding: 10px;
    text-align: left;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: url(images/forward.png) center/contain no-repeat;
        height: 100%;
        width: 50px;
    }
}

.radios {
    display: flex;
    width: 100%;
    background-color: $c-white;
    border-radius: $s-border-radius;
    margin-bottom: 10px;

    label {
        display: block;
        width: 50%;
        padding: 2px;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $c-blue;
        text-align: center;
        padding: 10px 20px;
        border-radius: $s-border-radius;
        cursor: pointer;
    }

    input:checked ~ .title {
        background-color: $c-green;
        color: $c-white;
        font-weight: bold;
    }

    input {
        display: none;
    }
}
</style>
