var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        on: {
          click: function($event) {
            return _vm.launchFilePicker()
          }
        }
      },
      [_vm._t("activator")],
      2
    ),
    _vm._v(" "),
    _c("input", {
      ref: "file",
      staticStyle: { display: "none" },
      attrs: { type: "file", name: _vm.uploadFieldName },
      on: {
        change: function($event) {
          return _vm.onFileChange($event.target.name, $event.target.files)
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        attrs: { "max-width": "300" },
        model: {
          value: _vm.errorDialog,
          callback: function($$v) {
            _vm.errorDialog = $$v
          },
          expression: "errorDialog"
        }
      },
      [_c("h3", { staticClass: "subheading" }, [_vm._v(_vm._s(_vm.errorText))])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }