<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."/>
      <template v-else-if="loadingState === 'done'">
        <h3 v-if="isNew">Nieuwe gebruiker aanmaken</h3>
        <h3 v-else>Gebruiker {{ user.name }}<br>van vestiging {{ user.branch.name}}<br>van {{ user.branch.company.name }} aanpassen</h3>
        <form class="form" id="userform" @submit.prevent="save()">
          <label class="two-columns">
            <span>Naam</span>
            <input type="text" v-model="details.name" required />
          </label>
          <label class="two-columns">
            <span>Telefoonnr (optioneel)</span>
            <input type="telnr" v-model="details.telnr" />
          </label>
          <label class="two-columns">
            <span>E-mailadres</span>
            <input type="email" v-model="details.email" required />
          </label>
          <label class="two-columns">
            <span>Wachtwoord</span>
            <input type="text"
                   autocomplete="new-password"
                   minLength="8"
                   v-model="details.password"
                   :placeholder="isNew ? 'minimaal 8 karakters' : 'niet aangepast'"
                   :required="isNew" />
          </label>
          <label class="two-columns">
            <span>Administrator</span>
            <input type="checkbox"
                   v-model="details.isAdministrator" />
          </label>
        </form>
      </template>
    </template>
    <template v-slot:actions v-if="loadingState === 'done'">
      <a v-if="!isNew"
         class="content__actions__action destructive"
         @click="if (triggeredDelete) deleteUser(); else triggeredDelete = true"
         :disabled="saving || deleting">
        <template v-if="!triggeredDelete">Gebruiker verwijderen</template>
        <template v-else>Weet je het zeker?</template>
      </a>
      <button form="userform"
              :disabled="saving || deleting"
              class="content__actions__action"
              :class="{destructive: details.isAdministrator && triggeredSave}">
        <loading-indicator type="inline" v-if="saving" />
        <template v-if="details.isAdministrator && triggeredSave">Opslaan als administrator?</template>
        <template v-else-if="isNew">Nieuwe gebruiker aanmaken</template>
        <template v-else>Aanpassingen opslaan</template>
      </button>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock,
        LoadingIndicator
    },
    props: ['companyId', 'branchId', 'id'],
    data: () => ({
        isNew: false,
        loadingState: 'none',
        user: null,
        details: {
            name: '',
            telnr: '',
            email: '',
            password: '',
            isAdministrator: false,
        },
        saving: false,
        triggeredDelete: false,
        triggeredSave: false,
        deleting: false
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            if (this.isNew) {
                this.$router.push({ name: 'companyBranch', params: { companyId: this.companyId, id: this.branchId } })
            } else {
                this.$router.push({ name: 'companyBranchUser', params: { companyId: this.companyId, branchId: this.branchId, id: this.id } })
            }
        },
        save() {
            if (this.details.isAdministrator && !this.triggeredSave) {
                this.triggeredSave = true
                return
            }

            this.saving = true

            const normalizeEmpty = value => (value === null || value === undefined ? '' : value)
            const maybe = name => {
                if (this.isNew && this.details[name]) return this.details[name]
                else if (!this.isNew && this.details[name] !== normalizeEmpty(this.user[name])) return this.details[name]
                else return undefined
            }

            const details = {}
            details.name = maybe('name')
            details.telnr = maybe('telnr')
            details.email = maybe('email')
            details.password = maybe('password')
            details.isAdministrator = maybe('isAdministrator')

            if (this.isNew){
                Api.createCompanyBranchUser(this.session.token, this.companyId, this.branchId, details)
                    .then(data => this.$router.push({ name: 'companyBranchUser', params: { companyId: data.user.branch.company.id, branchId: data.user.branch.id, id: data.user.id }}))
                    .catch(() => {
                        window.alert('Er is iets foutgegaan bij het aanmaken van de gebruiker. Probeer het opnieuw.')
                        this.saving = false
                    })
            } else {
                Api.editCompanyBranchUser(this.session.token, this.companyId, this.branchId, this.id, details)
                    .then(data => this.$router.push({ name: 'companyBranchUser', params: { companyId: data.user.branch.company.id, branchId: data.user.branch.id, id: data.user.id }}))
                    .catch(() => {
                        window.alert('Er is iets foutgegaan bij het opslaan van de aanpassingen. Probeer het opnieuw.')
                        this.saving = false
                    })
            }
        },
        loadUser() {
            if (!this.id) {
                this.isNew = true
                this.loadingState = 'done'
                return
            }

            this.loadingState = 'loading'
            Api.getCompanyBranchUser(this.session.token, this.companyId, this.branchId, this.id)
                .then(data => {
                    this.user = data.user

                    this.details.name = data.user.name || ''
                    this.details.telnr = data.user.telnr || ''
                    this.details.email = data.user.email || ''
                    this.details.isAdministrator = (data.user.isAdministrator === true)

                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
        deleteUser() {
            this.deleting = true
            Api.deleteCompanyBranchUser(this.session.token, this.companyId, this.branchId, this.id)
                .then(data => this.$router.push({ name: 'companyBranch', params: { companyId: this.companyId, id: this.branchId } }))
                .catch(() => {
                    window.alert('Er is iets foutgegaan bij het verwijderen van de gebruiker. Probeer het opnieuw.')
                    this.deleting = false
                })
        }
    },
    mounted() {
        this.loadUser()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.two-columns {
    display: flex;
    align-items: center;
    color: $c-white;
    margin-bottom: 10px;

    > * {
        flex: 1;
        margin-bottom: 0;
        min-width: 25%;
    }

    > input {
        max-width: 75%;
    }
}

p {
    color: $c-white;
}

.button.in-content.destructive {
    margin-top: 30px;
}
</style>
