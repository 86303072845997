<template>
<div class="content">
  <div class="content__main">
    <slot name="top"></slot>
    <div class="scrollable" :class="contentClass || ''">
      <slot name="default"></slot>
    </div>
  </div>
  <div class="content__actions">
    <slot name="actions"></slot>
  </div>
</div>
</template>

<script>
export default {
    props: ['contentClass']
}
</script>
