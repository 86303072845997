var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van je proefritten. Probeer het opnieuw."
                      }
                    })
                  : _vm.testDrives.length > 0
                  ? _c(
                      "div",
                      { staticClass: "testdrives scrollable" },
                      [
                        _c("h3", [_vm._v("Geregistreerde proefritten")]),
                        _vm._v(" "),
                        _vm._l(_vm.testDrives, function(td) {
                          return _c("test-drive-in-list", {
                            key: td.id,
                            attrs: { testdrive: td }
                          })
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "deletion-notice" }, [
                          _vm._v(
                            "\n          Afgeronde proefritten worden na 7 dagen verwijderd\n        "
                          )
                        ])
                      ],
                      2
                    )
                  : _c("div", { staticClass: "no-testdrives-indicator" }, [
                      _vm._v("\n        Geen proefritten gevonden\n      ")
                    ])
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "create content__actions__action",
                    attrs: { to: { name: "newTestDrive" } }
                  },
                  [_vm._v("Registreer nieuwe proefrit")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }