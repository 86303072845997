<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."/>
      <template v-else-if="loadingState === 'done'">
        <h3 v-if="isNew">Nieuwe vestiging aanmaken</h3>
        <h3 v-else>Vestiging {{ branch.name}}<br>van {{ branch.company.name }} aanpassen</h3>
        <form class="form" id="branchform" @submit.prevent="save()">
          <label class="two-columns">
            <span>Naam</span>
            <input type="text" v-model="details.name" required />
          </label>
          <p>Optionele details:</p>
          <label class="two-columns">
            <span>Plaats</span>
            <input type="text" v-model="details.city" />
          </label>
          <label class="two-columns">
            <span>Postcode</span>
            <input type="text" v-model="details.postcode" />
          </label>
          <label class="two-columns">
            <span>Adres</span>
            <input type="text" v-model="details.address" />
          </label>
          <label class="two-columns">
            <span>Telefoonnr</span>
            <input type="tel" v-model="details.telnr" />
          </label>
          <label class="two-columns">
            <span>E-mailadres</span>
            <input type="email" v-model="details.email" />
          </label>
        </form>
      </template>
    </template>
    <template v-slot:actions>
      <a v-if="!isNew"
         class="content__actions__action destructive"
         @click="if (triggeredDelete) deleteBranch(); else triggeredDelete = true"
         :disabled="deleting || saving">
        <template v-if="!triggeredDelete">Vestiging verwijderen</template>
        <template v-else>Weet je het zeker?</template>
      </a>
      <button form="branchform"
              :disabled="saving || deleting || !details.name"
              class="content__actions__action">
        <loading-indicator type="inline" v-if="saving" />
        <template v-if="isNew">Nieuwe vestiging aanmaken</template>
        <template v-else>Aanpassingen opslaan</template>
      </button>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock,
        LoadingIndicator
    },
    props: ['companyId', 'id'],
    data: () => ({
        isNew: false,
        loadingState: 'none',
        branch: null,
        details: {
            name: '',
            city: '',
            postcode: '',
            address: '',
            telnr: '',
            email: ''
        },
        saving: false,
        triggeredDelete: false,
        deleting: false
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            if (this.isNew) {
                this.$router.push({ name: 'company', params: { id: this.companyId } })
            } else {
                this.$router.push({ name: 'companyBranch', params: { companyId: this.companyId, id: this.id } })
            }
        },
        save() {
            if (!this.details.name) return

            this.saving = true

            const normalizeEmpty = value => (value === null || value === undefined ? '' : value)
            const maybe = name => {
                if (this.isNew && this.details[name]) return this.details[name]
                else if (!this.isNew && this.details[name] !== normalizeEmpty(this.branch[name])) return this.details[name]
                else return undefined
            }

            const details = {}
            details.name = maybe('name')
            details.city = maybe('city')
            details.postcode = maybe('postcode')
            details.address = maybe('address')
            details.telnr = maybe('telnr')
            details.email = maybe('email')

            if (this.isNew) {
                Api.createCompanyBranch(this.session.token, this.companyId, details)
                    .then(data => this.$router.push({ name: 'companyBranch', params: { companyId: data.branch.company.id, id: data.branch.id }}))
                    .catch(() => {
                        window.alert('Er is iets foutgegaan bij het aanmaken van de vestiging. Probeer het opnieuw.')
                        this.saving = false
                    })
            } else {
                Api.editCompanyBranch(this.session.token, this.companyId, this.id, details)
                    .then(data => this.$router.push({ name: 'companyBranch', params: { companyId: data.branch.company.id, id: data.branch.id }}))
                    .catch(() => {
                        window.alert('Er is iets foutgegaan bij het opslaan van de aanpassingen. Probeer het opnieuw.')
                        this.saving = false
                    })
            }
        },
        loadBranch() {
            if (!this.id) {
                this.isNew = true
                this.loadingState = 'done'
                return
            }

            this.loadingState = 'loading'
            Api.getCompanyBranch(this.session.token, this.companyId, this.id)
                .then(data => {
                    this.branch = data.branch

                    this.details.name = data.branch.name || ''
                    this.details.city = data.branch.city || ''
                    this.details.postcode = data.branch.postcode || ''
                    this.details.address = data.branch.address || ''
                    this.details.telnr = data.branch.telnr || ''
                    this.details.email = data.branch.email || ''

                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
        deleteBranch() {
            this.deleting = true
            Api.deleteCompanyBranch(this.session.token, this.companyId, this.id)
                .then(data => this.$router.push({ name: 'company', params: { id: this.companyId } }))
                .catch(() => {
                    window.alert('Er is iets foutgegaan bij het verwijderen van de vestiging. Probeer het opnieuw.')
                    this.deleting = false
                })
        }
    },
    mounted() {
        this.loadBranch()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.two-columns {
    display: flex;
    align-items: center;
    color: $c-white;
    margin-bottom: 10px;

    > * {
        flex: 1;
        margin-bottom: 0;
        min-width: 25%;
    }

    > input {
        max-width: 75%;
    }
}

p {
    color: $c-white;
}

.button.in-content.destructive {
    margin-top: 30px;
}
</style>
