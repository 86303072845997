<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."/>
      <div v-else-if="loadingState === 'done'" class="scrollable">
        <h2>{{ company.name }}</h2>
        <div class="summary">
          <div class="summary__spec" v-if="company.city">
            <div class="summary__spec__name">Plaats</div>
            <div class="summary__spec__value">{{ company.city }}</div>
          </div>
          <div class="summary__spec" v-if="company.postcode">
            <div class="summary__spec__name">Postcode</div>
            <div class="summary__spec__value">{{ company.postcode }}</div>
          </div>
          <div class="summary__spec" v-if="company.address">
            <div class="summary__spec__name">Adres</div>
            <div class="summary__spec__value">{{ company.address }}</div>
          </div>
          <div class="summary__spec" v-if="company.telnr">
            <div class="summary__spec__name">Telefoonnr</div>
            <div class="summary__spec__value">{{ company.telnr }}</div>
          </div>
          <div class="summary__spec" v-if="company.email">
            <div class="summary__spec__name">E-mailadres</div>
            <div class="summary__spec__value">{{ company.email }}</div>
          </div>
        </div>

        <h3>Vestigingen</h3>
        <div v-if="company.branches && company.branches.length > 0">
          <branch-in-list v-for="b in company.branches"
                          :key="b.id"
                          :branch="b"/>
        </div>
        <div v-else class="no-branches-indicator">
          Geen vestigingen gevonden
        </div>
        <div class="summary">
          <router-link :to="{ name: 'newCompanyBranch', params: { companyId: id } }"
                       class="create button in-content">
            Nieuwe vestiging
          </router-link>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'editCompany', params: { id: id } }"
                   class="content__actions__action">
        Bedrijf bewerken
      </router-link>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import BranchInList from '@/components/BranchInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        BranchInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    props: ['id'],
    data: () => ({
        company: [],
        loadingState: 'none'
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'companies' })
        },
        loadCompany() {
            this.loadingState = 'loading'
            Api.getCompany(this.session.token, this.id)
                .then(data => {
                    this.company = data.company
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        }
    },
    mounted() {
        this.loadCompany()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.create {
    margin-bottom: 20px;
}

.no-branches-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}
</style>
