<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <h2>Disclaimer</h2>
      <p>Lorem ipsum...</p>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock
    },
    methods: {
        onClickBackButton: function() {
            this.$router.push({ name: 'account' })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

p {
    padding: 0 20px;
    color: $c-white;
}
</style>
