var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van je accountgegevens. Probeer het opnieuw."
                      }
                    })
                  : [
                      _c(
                        "div",
                        { staticClass: "summary" },
                        [
                          _c("h2", [_vm._v("Uw gegevens")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summary__spec" }, [
                            _c("div", { staticClass: "summary__spec__name" }, [
                              _vm._v("Naam")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "summary__spec__value" }, [
                              _vm._v(_vm._s(_vm.name))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summary__spec" }, [
                            _c("div", { staticClass: "summary__spec__name" }, [
                              _vm._v("E-mailadres")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "summary__spec__value" }, [
                              _vm._v(_vm._s(_vm.email))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "summary__link",
                              attrs: { to: { name: "disclaimer" } }
                            },
                            [_vm._v("\n            Disclaimer\n          ")]
                          )
                        ],
                        1
                      )
                    ]
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _vm.session.isAdministrator
                  ? _c(
                      "router-link",
                      {
                        staticClass: "content__actions__action",
                        attrs: { to: { name: "companies" } }
                      },
                      [_vm._v("\n        Administratie\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "content__actions__action destructive",
                    on: {
                      click: function($event) {
                        if (_vm.triggeredLogOut) {
                          _vm.logOutOfApp()
                        } else {
                          _vm.triggeredLogOut = true
                        }
                      }
                    }
                  },
                  [
                    !_vm.triggeredLogOut
                      ? [_vm._v("Uitloggen")]
                      : [_vm._v("Bevestig uitloggen")]
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }