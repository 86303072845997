<template>
<router-link :to="{ name: 'company', params: { id: company.id } }"
             class="company">
  {{ company.name }}
</router-link>
</template>

<script>
export default {
    props: ['company']
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.company {
    display: flex;
    padding: $s-button-padding;
    border-bottom: 1px solid transparentize($c-white, 0.5);

    &:last-child {
        margin-bottom: 20px;
    }
}
</style>
