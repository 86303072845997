<template>
<div class="page">
  <dashboard-bar/>
  <content-block>
    <template v-slot:top>
      <div class="contact-buttons">
        <a class="button alt" href="tel:+31880333050"><img src="~images/phone-solid.svg"/></a>
        <a class="button alt" href="#" @click="openEmail(); return false;"><img src="~images/envelope-solid.svg"/></a>
      </div>
    </template>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van actieve proefritten. Probeer het opnieuw."/>
      <template v-else-if="activeTestDrives.length > 0">
        <h2>Actieve proefritten</h2>
        <div class="testdrives">
          <test-drive-in-list v-for="td in activeTestDrives"
                              :key="td.id"
                              :testdrive="td"/>
        </div>
      </template>
      <div v-else class="no-testdrives-indicator">
        Er zijn geen actieve proefritten
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'testDriveList' }" class="content__actions__action">Proefritten</router-link>
      <router-link :to="{ name: 'calculateLease' }" class="content__actions__action">Leaseprijs berekenen</router-link>

    </template>
  </content-block>
  <navigation-bar/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import TestDriveInList from '@/components/TestDriveInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        TestDriveInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    data: () => ({
        testDrives: [],
        loadingState: 'none'
    }),
    computed: {
        activeTestDrives() {
            if (!this.testDrives) return []
            return this.testDrives.filter(td => !td.finishedAt).slice(-2)
        },
        ...mapGetters([
            'session'
        ])
    },
    methods: {
        loadTestDrives() {
            this.loadingState = 'loading'
            Api.listTestDrives(this.session.token)
                .then(data => {
                    this.testDrives = data.testDrives,
                    this.loadingState = 'done'
                })
                .catch(data => this.loadingState = 'failed')
        },
        openEmail() {
            window.location = 'mailto:aanvraag@interlease.nl';
        }
    },
    mounted() {
        this.loadTestDrives()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.contact-buttons {
    display: flex;
    padding: 10px;
    background-color: transparentize($c-white, 0.8);

    a {
        display: flex;
        flex-grow: 1;
        height: 50px;
        padding: 12px;

        &:not(:first-child) {
            margin-left: 10px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.testdrives {
    overflow-y: auto;
}

.no-testdrives-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
