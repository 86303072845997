<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de bedrijven. Probeer het opnieuw."/>
      <div v-else-if="companies.length > 0" class="companies scrollable">
        <h3>Bedrijven</h3>
        <company-in-list v-for="c in companies"
                         :key="c.id"
                         :company="c"/>
      </div>
      <div v-else class="no-companies-indicator">
        Geen bedrijven gevonden
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'newCompany' }" class="create content__actions__action">Nieuw bedrijf</router-link>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import CompanyInList from '@/components/CompanyInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        CompanyInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    data: () => ({
        companies: [],
        loadingState: 'none'
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'account' })
        },
        loadCompanies() {
            this.loadingState = 'loading'
            Api.listCompanies(this.session.token)
                .then(data => {
                    this.companies = data.companies
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        }
    },
    mounted() {
        this.loadCompanies()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.no-companies-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
