<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block v-if="loadingState === 'loading'" key="loading">
    <loading-indicator/>
  </content-block>
  <content-block v-else-if="loadingState === 'failed'" key="failed">
    <error-indicator message="Er is iets foutgegaan bij het ophalen van de gegevens van deze proefrit. Probeer het opnieuw."/>
  </content-block>
  <content-block v-else-if="loadingState === 'done' && testDrive" key="testdrive">
    <template v-slot:default>
      <div class="summary">
        <h2 v-if="!testDrive.finishedAt">Proefrit vanaf {{ formattedTime(testDrive.createdAt) }}</h2>
        <h2 v-else>Proefrit van {{ formattedTime(testDrive.createdAt) }} tot {{ formattedTime(testDrive.finishedAt) }}</h2>
        <img v-if="testDrive.car.photo"
             :src="testDrive.car.photo"
             class="photo"/>
        <div class="summary__spec">
          <div class="summary__spec__name">Datum</div>
          <div class="summary__spec__value">{{ formattedDate(testDrive.createdAt) }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Voornaam</div>
          <div class="summary__spec__value">{{ testDrive.customer.firstName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Achternaam</div>
          <div class="summary__spec__value">{{ testDrive.customer.lastName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">E-mailadres</div>
          <div class="summary__spec__value">{{ testDrive.customer.email }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Telefoonnummer</div>
          <div class="summary__spec__value">{{ testDrive.customer.phoneNumber }}</div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <button v-if="!testDrive.finishedAt"
              class="content__actions__action"
              @click.prevent="finishTestDrive()">
        Proefrit afronden
      </button>
      <button v-else
              class="content__actions__action"
              @click.prevent="sendToCalculator()">
        Afgerond. Bereken leaseprijs
      </button>
    </template>
  </content-block>
  <content-block v-else-if="loadingState === 'done'" key="notfound">
    <template v-slot:default>
      Proefrit niet gevonden
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'
import { formattedTime, formattedDate } from '@/util'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    props: ['id'],
    data: () => ({
        testDrive: null,
        loadingState: 'none'
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'testDriveList' })
        },
        loadTestDrive() {
            this.loadingState = 'loading'
            Api.getTestDrive(this.session.token, this.id)
                .then(data => {
                    this.testDrive = data.testDrive
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
        finishTestDrive() {
            this.loadingState = 'loading'
            Api.finishTestDrive(this.session.token, this.id)
                .then(data => {
                    this.testDrive = data.testDrive
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
        sendToCalculator() {
            this.$router.push({ name: 'calculateLease', params: {
                prefill: {
                    firstName: this.testDrive.customer.firstName,
                    lastName: this.testDrive.customer.lastName,
                    email: this.testDrive.customer.email,
                    phone: this.testDrive.customer.phoneNumber,
                    licensePlate: this.testDrive.car.licensePlate,
                }
            }})
        },
        formattedTime,
        formattedDate,
    },
    mounted() {
        this.loadTestDrive()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.photo {
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}
</style>
