var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "form",
      {
        staticClass: "login-form",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login()
          }
        }
      },
      [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("images/interlease-logo.png") }
        }),
        _vm._v(" "),
        _vm.loginStatus === "FAILED"
          ? _c("p", { staticClass: "failed" }, [
              _vm._v("\n      Het inloggen is mislukt.\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          attrs: {
            type: "email",
            name: "email",
            maxlength: "255",
            placeholder: "Email",
            required: ""
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          attrs: {
            type: "password",
            name: "password",
            maxlength: "255",
            placeholder: "Wachtwoord",
            required: ""
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "alt",
            attrs: { disabled: _vm.loginStatus === "STARTED" }
          },
          [
            _vm.loginStatus === "STARTED"
              ? _c("loading-indicator", { attrs: { type: "inline" } })
              : _vm._e(),
            _vm._v(" Inloggen\n    ")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }