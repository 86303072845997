<template>
<div class="loading-indicator" :class="{inline: type === 'inline', block: type === 'block'}">
  <div class="spinner" :class="{inline: type === 'inline', block: type === 'block'}"></div>
</div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'block'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.loading-indicator {
    &.block {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.inline {
        display: inline-block;
        margin-right: 5px;
    }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.spinner {
    border-style: solid;
    border-color: $c-white;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spin 1s infinite;

    &.block {
        width: 50px;
        height: 50px;
        border-width: 5px;
    }

    &.inline {
        width: 1em;
        height: 1em;
        border-width: 2px;
    }
}
</style>
