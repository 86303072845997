var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function() {
                return [
                  _vm.loadingState === "loading"
                    ? _c("loading-indicator")
                    : _vm.loadingState === "failed"
                    ? _c("error-indicator", {
                        attrs: {
                          message:
                            "Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."
                        }
                      })
                    : _vm.loadingState === "done"
                    ? [
                        _vm.isNew
                          ? _c("h3", [_vm._v("Nieuwe gebruiker aanmaken")])
                          : _c("h3", [
                              _vm._v("Gebruiker " + _vm._s(_vm.user.name)),
                              _c("br"),
                              _vm._v(
                                "van vestiging " + _vm._s(_vm.user.branch.name)
                              ),
                              _c("br"),
                              _vm._v(
                                "van " +
                                  _vm._s(_vm.user.branch.company.name) +
                                  " aanpassen"
                              )
                            ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            staticClass: "form",
                            attrs: { id: "userform" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.save()
                              }
                            }
                          },
                          [
                            _c("label", { staticClass: "two-columns" }, [
                              _c("span", [_vm._v("Naam")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.details.name,
                                    expression: "details.name"
                                  }
                                ],
                                attrs: { type: "text", required: "" },
                                domProps: { value: _vm.details.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "two-columns" }, [
                              _c("span", [_vm._v("Telefoonnr (optioneel)")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.details.telnr,
                                    expression: "details.telnr"
                                  }
                                ],
                                attrs: { type: "telnr" },
                                domProps: { value: _vm.details.telnr },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details,
                                      "telnr",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "two-columns" }, [
                              _c("span", [_vm._v("E-mailadres")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.details.email,
                                    expression: "details.email"
                                  }
                                ],
                                attrs: { type: "email", required: "" },
                                domProps: { value: _vm.details.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "two-columns" }, [
                              _c("span", [_vm._v("Wachtwoord")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.details.password,
                                    expression: "details.password"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  autocomplete: "new-password",
                                  minLength: "8",
                                  placeholder: _vm.isNew
                                    ? "minimaal 8 karakters"
                                    : "niet aangepast",
                                  required: _vm.isNew
                                },
                                domProps: { value: _vm.details.password },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.details,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "two-columns" }, [
                              _c("span", [_vm._v("Administrator")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.details.isAdministrator,
                                    expression: "details.isAdministrator"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.details.isAdministrator
                                  )
                                    ? _vm._i(
                                        _vm.details.isAdministrator,
                                        null
                                      ) > -1
                                    : _vm.details.isAdministrator
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.details.isAdministrator,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.details,
                                            "isAdministrator",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.details,
                                            "isAdministrator",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.details,
                                        "isAdministrator",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ]
              },
              proxy: true
            },
            _vm.loadingState === "done"
              ? {
                  key: "actions",
                  fn: function() {
                    return [
                      !_vm.isNew
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "content__actions__action destructive",
                              attrs: { disabled: _vm.saving || _vm.deleting },
                              on: {
                                click: function($event) {
                                  if (_vm.triggeredDelete) {
                                    _vm.deleteUser()
                                  } else {
                                    _vm.triggeredDelete = true
                                  }
                                }
                              }
                            },
                            [
                              !_vm.triggeredDelete
                                ? [_vm._v("Gebruiker verwijderen")]
                                : [_vm._v("Weet je het zeker?")]
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "content__actions__action",
                          class: {
                            destructive:
                              _vm.details.isAdministrator && _vm.triggeredSave
                          },
                          attrs: {
                            form: "userform",
                            disabled: _vm.saving || _vm.deleting
                          }
                        },
                        [
                          _vm.saving
                            ? _c("loading-indicator", {
                                attrs: { type: "inline" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.details.isAdministrator && _vm.triggeredSave
                            ? [_vm._v("Opslaan als administrator?")]
                            : _vm.isNew
                            ? [_vm._v("Nieuwe gebruiker aanmaken")]
                            : [_vm._v("Aanpassingen opslaan")]
                        ],
                        2
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          true
        )
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }