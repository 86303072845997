var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bottom-bar", staticStyle: { display: "none" } },
    [
      _c(
        "router-link",
        {
          staticClass: "button alt",
          attrs: {
            to: { name: "dashboard" },
            tag: "button",
            "active-class": "active"
          }
        },
        [_c("span", { staticClass: "icon home" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "back-forward" }, [
        _c(
          "button",
          {
            staticClass: "button alt",
            on: {
              click: function($event) {
                return _vm.$emit("clickedBackButton")
              }
            }
          },
          [_c("span", { staticClass: "icon back" })]
        ),
        _vm._v(" "),
        _vm._m(0)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "button alt" }, [
      _c("span", { staticClass: "icon forward" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }