import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import LoginPage from '@/components/LoginPage'
import DashboardPage from '@/components/DashboardPage'
import TestDriveListPage from '@/components/TestDriveListPage'
import TestDrivePage from '@/components/TestDrivePage'
import NewTestDrivePage from '@/components/NewTestDrivePage'
import CalculateLeasePage from '@/components/CalculateLeasePage'
import AccountPage from '@/components/AccountPage'
import DisclaimerPage from '@/components/DisclaimerPage'
import AdministrationCompaniesPage from '@/components/AdministrationCompaniesPage'
import AdministrationCompanyPage from '@/components/AdministrationCompanyPage'
import AdministrationEditCompanyPage from '@/components/AdministrationEditCompanyPage'
import AdministrationCompanyBranchPage from '@/components/AdministrationCompanyBranchPage'
import AdministrationEditCompanyBranchPage from '@/components/AdministrationEditCompanyBranchPage'
import AdministrationCompanyBranchUserPage from '@/components/AdministrationCompanyBranchUserPage'
import AdministrationEditCompanyBranchUserPage from '@/components/AdministrationEditCompanyBranchUserPage'

const router = new Router({
    routes: [
        {
            path: '/login',
            component: LoginPage,
            name: 'login',
            meta: { public: true }
        },
        {
            path: '/',
            redirect: '/dashboard',
            meta: { public: true }
        },
        {
            path: '/dashboard',
            component: DashboardPage,
            name: 'dashboard'
        },
        {
            path: '/testdrive',
            component: TestDriveListPage,
            name: 'testDriveList'
        },
        {
            path: '/testdrive/new',
            component: NewTestDrivePage,
            name: 'newTestDrive'
        },
        {
            path: '/testdrive/:id',
            component: TestDrivePage,
            name: 'testDrive',
            props: true
        },
        {
            path:'/calculate-lease',
            component: CalculateLeasePage,
            name: 'calculateLease',
            props: true
        },
        {
            path:'/account',
            component: AccountPage,
            name: 'account'
        },
        {
            path: '/disclaimer',
            component: DisclaimerPage,
            name: 'disclaimer'
        },
        {
            path: '/administration/companies',
            component: AdministrationCompaniesPage,
            name: 'companies'
        },
        {
            path: '/administration/companies/new',
            component: AdministrationEditCompanyPage,
            name: 'newCompany'
        },
        {
            path: '/administration/companies/:id',
            component: AdministrationCompanyPage,
            name: 'company',
            props: true
        },
        {
            path: '/administration/companies/:id/edit',
            component: AdministrationEditCompanyPage,
            name: 'editCompany',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/new',
            component: AdministrationEditCompanyBranchPage,
            name: 'newCompanyBranch',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/:id',
            component: AdministrationCompanyBranchPage,
            name: 'companyBranch',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/:id/edit',
            component: AdministrationEditCompanyBranchPage,
            name: 'editCompanyBranch',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/:branchId/users/new',
            component: AdministrationEditCompanyBranchUserPage,
            name: 'newCompanyBranchUser',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/:branchId/users/:id',
            component: AdministrationCompanyBranchUserPage,
            name: 'companyBranchUser',
            props: true
        },
        {
            path: '/administration/companies/:companyId/branches/:branchId/users/:id/edit',
            component: AdministrationEditCompanyBranchUserPage,
            name: 'editCompanyBranchUser',
            props: true
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => !r.meta.public)) {
        if (store.getters.loginStatus !== 'FINISHED') {
            return next('/login')
        }
    }

    return next()
})

Vue.use(Router)

export default router
