<template>
<div class="error-indicator">
  <template v-if="message">
    {{ message }}
  </template>
  <template v-else>
    Er is iets foutgegaan.
  </template>
</div>
</template>

<script>
export default {
    props: ['message']
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.error-indicator {
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $c-white;
}
</style>
