var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar"),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("div", { staticClass: "contact-buttons" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button alt",
                      attrs: { href: "tel:+31880333050" }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("images/phone-solid.svg") }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button alt",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          _vm.openEmail()
                          return false
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("images/envelope-solid.svg") }
                      })
                    ]
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van actieve proefritten. Probeer het opnieuw."
                      }
                    })
                  : _vm.activeTestDrives.length > 0
                  ? [
                      _c("h2", [_vm._v("Actieve proefritten")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "testdrives" },
                        _vm._l(_vm.activeTestDrives, function(td) {
                          return _c("test-drive-in-list", {
                            key: td.id,
                            attrs: { testdrive: td }
                          })
                        }),
                        1
                      )
                    ]
                  : _c("div", { staticClass: "no-testdrives-indicator" }, [
                      _vm._v(
                        "\n        Er zijn geen actieve proefritten\n      "
                      )
                    ])
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "content__actions__action",
                    attrs: { to: { name: "testDriveList" } }
                  },
                  [_vm._v("Proefritten")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "content__actions__action",
                    attrs: { to: { name: "calculateLease" } }
                  },
                  [_vm._v("Leaseprijs berekenen")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }