<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."/>
      <div v-else-if="loadingState === 'done'" class="scrollable">
        <h2>Vestiging {{ branch.name }}<br>van {{ branch.company.name }}</h2>
        <div class="summary">
          <div class="summary__spec" v-if="branch.city">
            <div class="summary__spec__name">Plaats</div>
            <div class="summary__spec__value">{{ branch.city }}</div>
          </div>
          <div class="summary__spec" v-if="branch.postcode">
            <div class="summary__spec__name">Postcode</div>
            <div class="summary__spec__value">{{ branch.postcode }}</div>
          </div>
          <div class="summary__spec" v-if="branch.address">
            <div class="summary__spec__name">Adres</div>
            <div class="summary__spec__value">{{ branch.address }}</div>
          </div>
          <div class="summary__spec" v-if="branch.telnr">
            <div class="summary__spec__name">Telefoonnr</div>
            <div class="summary__spec__value">{{ branch.telnr }}</div>
          </div>
          <div class="summary__spec" v-if="branch.email">
            <div class="summary__spec__name">E-mailadres</div>
            <div class="summary__spec__value">{{ branch.email }}</div>
          </div>
        </div>

        <h3>Gebruikers</h3>
        <div v-if="branch.users && branch.users.length > 0">
          <user-in-list v-for="u in branch.users" :key="u.id" :user="u"/>
        </div>
        <div v-else class="no-users-indicator">
          Geen gebruikers gevonden
        </div>
        <div class="summary">
          <router-link v-if="loadingState === 'done'"
                       :to="{ name: 'newCompanyBranchUser', params: { companyId: branch.company.id, branchId: branch.id } }"
                       class="create button in-content">
            Nieuwe gebruiker
          </router-link>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'editCompanyBranch', params: { companyId: companyId, id: id } }"
                   class="content__actions__action">
        Vestiging bewerken
      </router-link>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import UserInList from '@/components/UserInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        UserInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    props: ['companyId', 'id'],
    data: () => ({
        branch: [],
        loadingState: 'none',
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'company', params: { id: this.companyId } })
        },
        loadBranch() {
            this.loadingState = 'loading'
            Api.getCompanyBranch(this.session.token, this.companyId, this.id)
                .then(data => {
                    this.branch = data.branch
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
    },
    mounted() {
        this.loadBranch()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.create {
    margin-bottom: 20px;
}

.no-users-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
}
</style>
