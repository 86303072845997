var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."
                      }
                    })
                  : _vm.loadingState === "done"
                  ? _c("div", { staticClass: "scrollable" }, [
                      _c("h2", [
                        _vm._v("Vestiging " + _vm._s(_vm.branch.name)),
                        _c("br"),
                        _vm._v("van " + _vm._s(_vm.branch.company.name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary" }, [
                        _vm.branch.city
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Plaats")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.branch.city))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.branch.postcode
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Postcode")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.branch.postcode))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.branch.address
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Adres")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.branch.address))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.branch.telnr
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Telefoonnr")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.branch.telnr))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.branch.email
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("E-mailadres")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.branch.email))]
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("Gebruikers")]),
                      _vm._v(" "),
                      _vm.branch.users && _vm.branch.users.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.branch.users, function(u) {
                              return _c("user-in-list", {
                                key: u.id,
                                attrs: { user: u }
                              })
                            }),
                            1
                          )
                        : _c("div", { staticClass: "no-users-indicator" }, [
                            _vm._v(
                              "\n          Geen gebruikers gevonden\n        "
                            )
                          ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "summary" },
                        [
                          _vm.loadingState === "done"
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "create button in-content",
                                  attrs: {
                                    to: {
                                      name: "newCompanyBranchUser",
                                      params: {
                                        companyId: _vm.branch.company.id,
                                        branchId: _vm.branch.id
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Nieuwe gebruiker\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "content__actions__action",
                    attrs: {
                      to: {
                        name: "editCompanyBranch",
                        params: { companyId: _vm.companyId, id: _vm.id }
                      }
                    }
                  },
                  [_vm._v("\n        Vestiging bewerken\n      ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }