var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "content__main" },
      [
        _vm._t("top"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scrollable", class: _vm.contentClass || "" },
          [_vm._t("default")],
          2
        )
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content__actions" }, [_vm._t("actions")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }