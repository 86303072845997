<template>
<div>
  <!-- slot for parent component to activate the file changer -->
  <div @click="launchFilePicker()">
    <slot name="activator"></slot>
  </div>
  <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
  <input type="file"
         ref="file"
         :name="uploadFieldName"
         @change="onFileChange(
                  $event.target.name, $event.target.files)"
         style="display:none">
  <!-- error dialog displays any potential errors -->
  <div v-model="errorDialog" max-width="300">
    <h3 class="subheading">{{errorText}}</h3>
  </div>
</div>
</template>

<script>
export default {
    name: 'photo-uploader',
    data: () => ({
        errorDialog: null,
        errorText: '',
        uploadFieldName: 'file',
        maxSize: 4096
    }),
    props: {
        // Use "value" here to enable compatibility with v-model
        value: Object,
    },
    methods: {
        launchFilePicker() {
            this.$refs.file.click();
        },
        onFileChange(fieldName, file) {
            const { maxSize } = this
            let imageFile = file[0]
            // check if the user actually selected a file
            if (file.length > 0) {
                let size = imageFile.size / maxSize / maxSize
                if (!imageFile.type.match('image.*')) {
                    // check whether the upload is an image
                    this.errorDialog = true
                    this.errorText = 'Please choose an image file'
                } else if (size > 1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = 'De geselecteerde foto is te groot. Kies een foto van maximaal 4MB'
                } else {
                    this.errorText = ''
                    // Append file into FormData & turn file into image URL
                    let formData = new FormData()
                    let imageURL = URL.createObjectURL(imageFile)
                    formData.append(fieldName, imageFile)
                    
                    // Emit FormData & image URL to the parent component
                    this.$emit('input', { formData, imageURL })
                }
            }
        }
    }
}
</script>
