import axios from 'axios'

const authHeader = (token) => {
    return {
        'Authorization': 'Bearer ' + token
    }
}

const login = (email, password) => {
    return axios.post('/api/login', {
        email,
        password
    }).then(response => Promise.resolve(response.data))
}

const ping = (token) => {
    return axios.get('/api/ping', {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const listTestDrives = (token) => {
    return axios.get('/api/testdrives', {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const createTestDrive = (token, data) => {
    return axios.post('/api/testdrives', data, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const getTestDrive = (token, id) => {
    return axios.get('/api/testdrives/' + id, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const finishTestDrive = (token, id) => {
    return axios.post('/api/testdrives/' + id + '/finish', {}, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const readBlob = (objectUrl) => {
    return axios.get(objectUrl, {
        responseType: 'blob'
    }).then(response => Promise.resolve(response.data))
}

const getLeaseCalculation = (token, data) => {
    return axios.post('/api/lease/calculation', data, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const sendLeaseCalculation = (token, data) => {
    return axios.post('/api/lease/calculation/send', data, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const sendLeaseRequest = (token, data) => {
    return axios.post('/api/lease/calculation/request', data, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const listCompanies = (token) => {
    return axios.get('/api/companies', {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const getCompany = (token, id) => {
    return axios.get('/api/companies/' + id, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const createCompany = (token, details) => {
    return axios.post('/api/companies', details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const editCompany = (token, id, details) => {
    return axios.post('/api/companies/' + id, details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const deleteCompany = (token, id) => {
    return axios.post('/api/companies/' + id + '?_method=DELETE', {}, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const getCompanyBranch = (token, companyId, id) => {
    return axios.get('/api/companies/' + companyId + '/branches/' + id, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const createCompanyBranch = (token, companyId, details) => {
    return axios.post('/api/companies/' + companyId + '/branches', details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const editCompanyBranch = (token, companyId, id, details) => {
    return axios.post('/api/companies/' + companyId + '/branches/' + id, details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const deleteCompanyBranch = (token, companyId, id) => {
    return axios.post('/api/companies/' + companyId + '/branches/' + id + '?_method=DELETE', {}, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const getCompanyBranchUser = (token, companyId, branchId, id) => {
    return axios.get('/api/companies/' + companyId + '/branches/' + branchId + '/users/' + id, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const createCompanyBranchUser = (token, companyId, branchId, details) => {
    return axios.post('/api/companies/' + companyId + '/branches/' + branchId + '/users', details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const editCompanyBranchUser = (token, companyId, branchId, id, details) => {
    return axios.post('/api/companies/' + companyId + '/branches/' + branchId + '/users/' + id, details, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

const deleteCompanyBranchUser = (token, companyId, branchId, id) => {
    return axios.post('/api/companies/' + companyId + '/branches/' + branchId + '/users/' + id + '?_method=DELETE', {}, {
        headers: {...authHeader(token)}
    }).then(response => Promise.resolve(response.data))
}

export default {
    readBlob,
    login,
    ping,
    listTestDrives,
    createTestDrive,
    getTestDrive,
    finishTestDrive,
    getLeaseCalculation,
    sendLeaseCalculation,
    sendLeaseRequest,
    listCompanies,
    getCompany,
    createCompany,
    editCompany,
    deleteCompany,
    getCompanyBranch,
    createCompanyBranch,
    editCompanyBranch,
    deleteCompanyBranch,
    getCompanyBranchUser,
    createCompanyBranchUser,
    editCompanyBranchUser,
    deleteCompanyBranchUser,
}
