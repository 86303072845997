import Api from '@/api'
import router from '@/router'

const state = {
    loginStatus: 'NONE',
    loginEmail: null,
    session: null
}

const persist = (state) => {
    window.localStorage.setItem('loginStatus', state.loginStatus)
    window.localStorage.setItem('loginEmail', state.loginEmail)
    window.localStorage.setItem('session', JSON.stringify(state.session))
}

const unpersist = () => {
    try {
        return {
            'loginStatus': window.localStorage.getItem('loginStatus'),
            'loginEmail': window.localStorage.getItem('loginEmail'),
            'session': JSON.parse(window.localStorage.getItem('session'))
        }
    } catch (e) {
        return {}
    }
}

const getters = {
    session: state => state.session,
    loginStatus: state => state.loginStatus,
    loginEmail: state => state.loginEmail
}

const actions = {
    init({ commit, dispatch, getters }) {
        const data = unpersist()
        if (data) {
            commit('loginRestored', data)
        }
    },
    loginWithPassword({ commit, dispatch }, details) {
        commit('loginStarted', details.email);
        return Api
            .login(details.email, details.password)
            .then(data => {
                commit('loginFinished', data.session)
            })
            .catch(() => commit('loginFailed'))
    },
    logOut({ commit, dispatch, getters }) {
        commit('loggedOut')
        router.push('/login')
    }
}

const mutations = {
    loginStarted(state, email) {
        state.loginStatus = 'STARTED'
        state.loginEmail = email
        persist(state)
    },
    loginFailed(state) {
        state.loginStatus = 'FAILED'
        persist(state)
    },
    loginFinished(state, session) {
        state.loginStatus = 'FINISHED'
        state.session = session
        persist(state)
    },
    loginRestored(state, data) {
        state.session = data.session
        state.loginStatus = data.loginStatus
        state.loginEmail = data.loginEmail
        // This is supposed to be called to restore persisted data, so it does
        // not itself persist.
    },
    loggedOut(state) {
        state.loginStatus = 'NONE'
        state.session = null
        state.loginEmail = null
        persist(state)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
