var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "branch",
      attrs: {
        to: {
          name: "companyBranch",
          params: { companyId: _vm.branch.company.id, id: _vm.branch.id }
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.branch.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }