<template>
<router-link :to="{ name: 'testDrive', params: { id: testdrive.id } }"
             class="testdrive">
  <div class="testdrive__details">
    <div class="testdrive__customer">
      <span class="testdrive__firstname">{{ testdrive.customer.firstName }}</span>
      <span class="testdrive__lastname">{{ testdrive.customer.lastName }}</span>
    </div>
    <div class="testdrive__car">
      <span class="testdrive__licenseplate">{{ testdrive.car.licensePlate }}</span>
    </div>
    <div class="testdrive__timespan">
      <template v-if="testdrive.finishedAt">
        <span class="testdrive__from">Van {{ formattedTime(testdrive.createdAt) }}</span>
        <span class="testdrive__to"> tot {{ formattedTime(testdrive.finishedAt) }}</span>
      </template>
      <template v-else>
        Sinds {{ formattedTime(testdrive.createdAt) }}
      </template>
    </div>
  </div>
  <div class="testdrive__status"
       :class="{ active: !testdrive.finishedAt, finished: testdrive.finishedAt }">
    <template v-if="!testdrive.finishedAt">Nu bezig</template>
  </div>
</router-link>
</template>

<script>
export default {
    props: ['testdrive'],
    methods: {
        formattedTime(str) {
            if (!str) return ''
            const date = new Date(str)
            return date.toLocaleString('nl-NL', {
                hour: '2-digit',
                minute: '2-digit',
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.testdrive {
    text-transform: none;
    background-color: transparent;
    border-bottom: 1px solid transparentize($c-white, 0.5);
    display: flex;
    align-items: center;

    font-weight: bold;
    padding: $s-button-padding;

    &__details {
        flex-grow: 1;
    }

    &__status.active {
        color: #aadd00;
    }
}
</style>
