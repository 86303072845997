<template>
<div class="header-bar">
  <button v-if="$listeners && $listeners.clickedBackButton"
          class="button back"
          @click="$emit('clickedBackButton')">
  </button>
  <div v-else class="button placeholder"></div>
  <router-link :to="{ name: 'dashboard' }"
               class="logo-link">
    <img src="~images/interlease-logo.png" class="logo">
  </router-link>
  <router-link :to="{ name: 'account' }"
               active-class="active"
               tag="button"
               class="button account">
  </router-link>
</div>
</template>

<script>
export default {
    name: 'dashboard-bar',
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $c-white;
    z-index: 10;
    height: 60px;
}

.logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 30px;
    margin: 0 10px;
}

.button {
    width: 50px;
    height: 50px;
    padding: 0;

    &.account {
        background: url(images/user.png) center no-repeat;
        margin-right: 5px;
    }

    &.back {
        background: url(images/back.png) center/contain no-repeat;
        margin-left: 5px;
    }

    &.placeholder {
        cursor: inherit;
        margin-left: 5px;

        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
        }
    }

    &.active {
        background-color: rgba(0, 0, 0, 0.1);
    }
}
</style>
