var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."
                      }
                    })
                  : _vm.loadingState === "done"
                  ? [
                      _vm.isNew
                        ? _c("h3", [_vm._v("Nieuw bedrijf aanmaken")])
                        : _c("h3", [
                            _vm._v(_vm._s(_vm.company.name) + " aanpassen")
                          ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          attrs: { id: "companyform" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("Naam")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.name,
                                  expression: "details.name"
                                }
                              ],
                              attrs: { type: "text", required: "" },
                              domProps: { value: _vm.details.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v("Optionele details:")]),
                          _vm._v(" "),
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("Plaats")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.city,
                                  expression: "details.city"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.details.city },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "city",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("Postcode")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.postcode,
                                  expression: "details.postcode"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.details.postcode },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "postcode",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("Adres")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.address,
                                  expression: "details.address"
                                }
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.details.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "address",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("Telefoonnr")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.telnr,
                                  expression: "details.telnr"
                                }
                              ],
                              attrs: { type: "tel" },
                              domProps: { value: _vm.details.telnr },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "telnr",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "two-columns" }, [
                            _c("span", [_vm._v("E-mailadres")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.details.email,
                                  expression: "details.email"
                                }
                              ],
                              attrs: { type: "email" },
                              domProps: { value: _vm.details.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.details,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "a",
                  {
                    staticClass: "content__actions__action destructive",
                    attrs: { disabled: _vm.saving || _vm.deleting },
                    on: {
                      click: function($event) {
                        if (_vm.triggeredDelete) {
                          _vm.deleteCompany()
                        } else {
                          _vm.triggeredDelete = true
                        }
                      }
                    }
                  },
                  [
                    !_vm.triggeredDelete
                      ? [_vm._v("Bedrijf verwijderen")]
                      : [_vm._v("Weet je het zeker?")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "content__actions__action",
                    attrs: {
                      form: "companyform",
                      disabled: _vm.saving || _vm.deleting || !_vm.details.name
                    }
                  },
                  [
                    _vm.saving
                      ? _c("loading-indicator", { attrs: { type: "inline" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNew
                      ? [_vm._v("Nieuw bedrijf aanmaken")]
                      : [_vm._v("Aanpassingen opslaan")]
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }