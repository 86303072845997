<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van je accountgegevens. Probeer het opnieuw."/>
      <template v-else>
        <div class="summary">
          <h2>Uw gegevens</h2>
          <div class="summary__spec">
            <div class="summary__spec__name">Naam</div>
            <div class="summary__spec__value">{{ name }}</div>
          </div>
          <div class="summary__spec">
            <div class="summary__spec__name">E-mailadres</div>
            <div class="summary__spec__value">{{ email }}</div>
          </div>
          <router-link :to="{ name: 'disclaimer' }" class="summary__link">
            Disclaimer
          </router-link>
        </div>
      </template>
    </template>
    <template v-slot:actions>
      <router-link v-if="session.isAdministrator"
                   :to="{ name: 'companies' }"
                   class="content__actions__action">
        Administratie
      </router-link>
      <a class="content__actions__action destructive"
         @click="if (triggeredLogOut) logOutOfApp(); else triggeredLogOut = true">
        <template v-if="!triggeredLogOut">Uitloggen</template>
        <template v-else>Bevestig uitloggen</template>
      </a>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import { mapActions, mapGetters } from 'vuex'
import Api from '@/api'

export default {
    data: () => ({
        name: '',
        email: '',
        loadingState: 'none',
        triggeredLogOut: false
    }),
    computed: mapGetters([
        'session'
    ]),
    components: {
        DashboardBar,
        NavigationBar,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    methods: {
        logOutOfApp() {
            this.logOut()
        },
        ...mapActions([
            'logOut'
        ]),
        onClickBackButton: function() {
            this.$router.push({ name: 'dashboard' })
        },
        loadPersonalInformation() {
            this.loadingState = 'loading'
            Api.ping(this.session.token)
                .then(data => {
                    this.name = data.user.name
                    this.email = data.user.email
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        }
    },
    mounted() {
        this.loadPersonalInformation()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';
</style>
