var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "testdrive",
      attrs: { to: { name: "testDrive", params: { id: _vm.testdrive.id } } }
    },
    [
      _c("div", { staticClass: "testdrive__details" }, [
        _c("div", { staticClass: "testdrive__customer" }, [
          _c("span", { staticClass: "testdrive__firstname" }, [
            _vm._v(_vm._s(_vm.testdrive.customer.firstName))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "testdrive__lastname" }, [
            _vm._v(_vm._s(_vm.testdrive.customer.lastName))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "testdrive__car" }, [
          _c("span", { staticClass: "testdrive__licenseplate" }, [
            _vm._v(_vm._s(_vm.testdrive.car.licensePlate))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "testdrive__timespan" },
          [
            _vm.testdrive.finishedAt
              ? [
                  _c("span", { staticClass: "testdrive__from" }, [
                    _vm._v(
                      "Van " +
                        _vm._s(_vm.formattedTime(_vm.testdrive.createdAt))
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "testdrive__to" }, [
                    _vm._v(
                      " tot " +
                        _vm._s(_vm.formattedTime(_vm.testdrive.finishedAt))
                    )
                  ])
                ]
              : [
                  _vm._v(
                    "\n        Sinds " +
                      _vm._s(_vm.formattedTime(_vm.testdrive.createdAt)) +
                      "\n      "
                  )
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "testdrive__status",
          class: {
            active: !_vm.testdrive.finishedAt,
            finished: _vm.testdrive.finishedAt
          }
        },
        [!_vm.testdrive.finishedAt ? [_vm._v("Nu bezig")] : _vm._e()],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }