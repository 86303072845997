var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _vm.step === "CUSTOMER"
        ? _c("content-block", {
            key: "CUSTOMER",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "customerform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v("Auto")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "two-columns" }, [
                          _c("div", { staticClass: "input-label" }, [
                            _vm._v("Kenteken")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.licensePlate,
                                expression: "licensePlate"
                              }
                            ],
                            attrs: {
                              type: "text",
                              name: "licence-plate",
                              maxlength: "16",
                              pattern: "^[a-zA-Z0-9-]+$",
                              placeholder: "Kenteken",
                              required: ""
                            },
                            domProps: { value: _vm.licensePlate },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.licensePlate = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Gegevens klant")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstName,
                              expression: "firstName"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "firstName",
                            maxlength: "128",
                            placeholder: "Voornaam",
                            required: ""
                          },
                          domProps: { value: _vm.firstName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.firstName = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lastName,
                              expression: "lastName"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "lastName",
                            maxlength: "128",
                            placeholder: "Achternaam",
                            required: ""
                          },
                          domProps: { value: _vm.lastName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lastName = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          attrs: {
                            type: "email",
                            name: "email",
                            maxlength: "255",
                            placeholder: "Emailadres",
                            required: ""
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone"
                            }
                          ],
                          attrs: {
                            type: "phone",
                            name: "phone",
                            maxlength: "64",
                            pattern: "^\\+?[0-9 -]+$",
                            placeholder: "Telefoonnummer",
                            required: ""
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.leaseContext == "business"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.kvkNumber,
                                  expression: "kvkNumber"
                                }
                              ],
                              attrs: {
                                type: "text",
                                name: "kvkNumber",
                                maxlength: "64",
                                pattern: "^[0-9]+$",
                                placeholder: "KVK-nummer",
                                required: ""
                              },
                              domProps: { value: _vm.kvkNumber },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.kvkNumber = $event.target.value
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { form: "customerform" }
                      },
                      [_vm._v("Volgende")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.step === "CALC1"
        ? _c("content-block", {
            key: "CALC1",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "carform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v("Prijs")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "two-columns" }, [
                          _c(
                            "div",
                            { staticClass: "input-label" },
                            [
                              _vm._v("Verkoopprijs "),
                              _vm.leaseContext === "business"
                                ? [_c("br"), _vm._v("excl. BTW")]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.price,
                                expression: "price"
                              }
                            ],
                            attrs: {
                              type: "number",
                              name: "price",
                              min: "200",
                              max: "9999999",
                              placeholder: "Verkoopprijs",
                              required: ""
                            },
                            domProps: { value: _vm.price },
                            on: {
                              change: function($event) {
                                return _vm.updateCalculationFromPrice()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.price = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "h3",
                          [
                            _vm._v("\n          Aanbetaling\n          "),
                            _c("loading-indicator", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    false &&
                                    _vm.calculatorState === "updating" &&
                                    _vm.initial === "",
                                  expression:
                                    "false && calculatorState === 'updating' && initial === ''"
                                }
                              ],
                              attrs: { type: "inline" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "two-columns" }, [
                          _c("div", { staticClass: "input-label" }, [
                            _vm._v("Inruilbedrag")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tradein,
                                expression: "tradein"
                              }
                            ],
                            ref: "tradein",
                            attrs: {
                              disabled: _vm.price <= 0,
                              type: "number",
                              name: "tradein",
                              min: "0",
                              max: _vm.initialMax,
                              placeholder: "Inruilbedrag"
                            },
                            domProps: { value: _vm.tradein },
                            on: {
                              change: function($event) {
                                return _vm.updateCalculation()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.tradein = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "two-columns" }, [
                          _c("div", { staticClass: "input-label" }, [
                            _vm._v("Aanbetaling")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.extraInitial,
                                expression: "extraInitial"
                              }
                            ],
                            ref: "extraInitial",
                            attrs: {
                              disabled: _vm.price <= 0,
                              type: "number",
                              name: "extraInitial",
                              min: _vm.extraInitialMin,
                              max: _vm.extraInitialMax,
                              placeholder: "Aanbetaling"
                            },
                            domProps: { value: _vm.extraInitial },
                            on: {
                              change: function($event) {
                                return _vm.updateCalculation()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.extraInitial = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.calculatorState !== "none",
                                expression: "calculatorState !== 'none'"
                              }
                            ],
                            staticClass: "two-columns"
                          },
                          [
                            _c("div", { staticClass: "input-label" }, [
                              _vm._v("Totale aanbetaling")
                            ]),
                            _vm._v(" "),
                            _vm.calculatorState === "done"
                              ? _c("div", { staticClass: "simple-value" }, [
                                  _vm._v(
                                    _vm._s(_vm.formattedPrice(_vm.initial))
                                  )
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "simple-value" },
                                  [
                                    _c("loading-indicator", {
                                      attrs: { type: "inline" }
                                    })
                                  ],
                                  1
                                )
                          ]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { form: "carform" }
                      },
                      [_vm._v("Volgende")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.step === "CALC2" && _vm.calculatorState === "loading"
        ? _c(
            "content-block",
            { key: "CALCULATORLOADING" },
            [_c("loading-indicator")],
            1
          )
        : _vm.step === "CALC2" && _vm.calculatorState === "failed"
        ? _c(
            "content-block",
            { key: "CALCULATORLOADING" },
            [
              _c("error-indicator", {
                attrs: {
                  message:
                    "Er is iets misgegaan bij het ophalen van de berekening. Probeer het opnieuw."
                }
              })
            ],
            1
          )
        : _vm.step === "CALC2" && _vm.calculatorState !== "loading"
        ? _c("content-block", {
            key: "CALC2",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "leaseform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v("Bereken het maandbedrag")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "two-columns" }, [
                          _c("div", { staticClass: "input-label" }, [
                            _vm._v("Leenbedrag")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "simple-value" }, [
                            _vm._v(_vm._s(_vm.formattedPrice(_vm.loanValue)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.leaseContext === "business",
                                expression: "leaseContext === 'business'"
                              }
                            ],
                            staticClass: "two-columns"
                          },
                          [
                            _c("div", { staticClass: "input-label" }, [
                              _vm._v("Slottermijn")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.residual,
                                  expression: "residual"
                                }
                              ],
                              attrs: {
                                type: "number",
                                name: "residual",
                                min: _vm.residualMin,
                                max: _vm.residualMax,
                                placeholder: "Slottermijn",
                                required: ""
                              },
                              domProps: { value: _vm.residual },
                              on: {
                                change: function($event) {
                                  return _vm.updateCalculation()
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.residual = $event.target.value
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.calculatorState === "done"
                          ? [
                              _c("div", { staticClass: "notice" }, [
                                _vm._v("Selecteer een looptijd")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.installmentsOptions, function(option) {
                                return _c(
                                  "div",
                                  { staticClass: "two-columns" },
                                  [
                                    _c("span", { staticClass: "months-text" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(option.months) +
                                          " maanden\n            "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "months-button",
                                        on: {
                                          click: function($event) {
                                            _vm.installments = option.months
                                            _vm.monthlyPrice = option.price
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formattedPrice(option.price)
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "notice" }, [
                                _vm._v(
                                  "Let op, deze prijzen zijn een indicatie."
                                )
                              ])
                            ]
                          : _c("loading-indicator")
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.step === "CONFIRM"
        ? _c("content-block", {
            key: "CONFIRM",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("h3", [_vm._v("Bevestig de ingevulde gegevens")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary" }, [
                      _c("h4", { staticClass: "summary__title" }, [
                        _vm._v("De klant")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Voornaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.firstName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Achternaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.lastName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("E-mailadres")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.email))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Telefoonnummer")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.phone))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("KVK-nummer")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.kvkNumber))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary" }, [
                      _c("h4", { staticClass: "summary__title" }, [
                        _vm._v("Het voertuig")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Kenteken")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.licensePlate))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Verkoopprijs")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.formattedPrice(_vm.price)))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary" }, [
                      _c("h4", { staticClass: "summary__title" }, [
                        _vm._v("De lening")
                      ]),
                      _vm._v(" "),
                      _vm.initial > 0
                        ? _c("div", { staticClass: "summary__spec" }, [
                            _c("div", { staticClass: "summary__spec__name" }, [
                              _vm._v("Aanbetaling")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "summary__spec__value" }, [
                              _vm._v(_vm._s(_vm.formattedPrice(_vm.initial)))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Leenbedrag")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.formattedPrice(_vm.loanValue)))
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.residual > 0
                        ? _c("div", { staticClass: "summary__spec" }, [
                            _c("div", { staticClass: "summary__spec__name" }, [
                              _vm._v("Slottermijn")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "summary__spec__value" }, [
                              _vm._v(_vm._s(_vm.formattedPrice(_vm.residual)))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Betaaltermijn")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.installments) + " maanden")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Maandbedrag")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.formattedPrice(_vm.monthlyPrice)))
                        ])
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { disabled: _vm.sending || _vm.requesting },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sendCalculation()
                          }
                        }
                      },
                      [
                        _vm.sending
                          ? _c("loading-indicator", {
                              attrs: { type: "inline" }
                            })
                          : _vm._e(),
                        _vm._v(" Berekening verzenden\n      ")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { disabled: _vm.sending || _vm.requesting },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.sendRequest()
                          }
                        }
                      },
                      [
                        _vm.requesting
                          ? _c("loading-indicator", {
                              attrs: { type: "inline" }
                            })
                          : _vm._e(),
                        _vm._v(" Aanvraag verzenden\n      ")
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }