<template>
<div class="page">
  <form
    @submit.prevent="login()"
    class="login-form">
    <img src="~images/interlease-logo.png" class="logo">
    <p v-if="loginStatus === 'FAILED'" class="failed">
      Het inloggen is mislukt.
    </p>
    <input type="email" name="email" v-model="email"
           maxlength="255"
           placeholder="Email"
           required/>
    <input type="password" name="password" v-model="password"
           maxlength="255"
           placeholder="Wachtwoord"
           required/>
    <button :disabled="loginStatus === 'STARTED'" class="alt">
      <loading-indicator type="inline" v-if="loginStatus === 'STARTED'" /> Inloggen
    </button>
  </form>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
    data: () => ({
        email: '',
        password: ''
    }),
    computed: mapGetters([
        'loginStatus',
        'loginEmail'
    ]),
    watch: {
        loginStatus(status) {
            if (status === 'FINISHED') {
                this.$router.push({ name: 'dashboard' })
            }
        }
    },
    methods: {
        login() {
            this.loginWithPassword({
                email: this.email,
                password: this.password
            })
        },
        ...mapActions([
            'loginWithPassword',
            'confirmLogin',
            'logOut'
        ])
    },
    mounted() {
        if (this.loginStatus === 'FINISHED') {
            this.$router.push('dashboard')
        }
    },
    components: {
        LoadingIndicator
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.page {
    align-items: center;
    justify-content: center;
}

.logo {
    margin-bottom: 20px;
    width: 100%;
    max-width: 250px;
}

.failed {
    text-align: center;
    color: $c-red;
}

.login-form {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 20px;
    border-radius: 5px;
    background-color: $c-white;

    input {
        padding: 10px 20px;
        margin-bottom: 10px;
    }

    button {
        margin-bottom: 10px;
    }
}
</style>
