var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van de bedrijfsinformatie. Probeer het opnieuw."
                      }
                    })
                  : _vm.loadingState === "done"
                  ? _c("div", { staticClass: "scrollable" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.company.name))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary" }, [
                        _vm.company.city
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Plaats")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.company.city))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.company.postcode
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Postcode")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.company.postcode))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.company.address
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Adres")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.company.address))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.company.telnr
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Telefoonnr")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.company.telnr))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.company.email
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("E-mailadres")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.company.email))]
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("Vestigingen")]),
                      _vm._v(" "),
                      _vm.company.branches && _vm.company.branches.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.company.branches, function(b) {
                              return _c("branch-in-list", {
                                key: b.id,
                                attrs: { branch: b }
                              })
                            }),
                            1
                          )
                        : _c("div", { staticClass: "no-branches-indicator" }, [
                            _vm._v(
                              "\n          Geen vestigingen gevonden\n        "
                            )
                          ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "summary" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "create button in-content",
                              attrs: {
                                to: {
                                  name: "newCompanyBranch",
                                  params: { companyId: _vm.id }
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            Nieuwe vestiging\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "content__actions__action",
                    attrs: {
                      to: { name: "editCompany", params: { id: _vm.id } }
                    }
                  },
                  [_vm._v("\n        Bedrijf bewerken\n      ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }