var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("h2", [_vm._v("Disclaimer")]),
                _vm._v(" "),
                _c("p", [_vm._v("Lorem ipsum...")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }