<template>
<router-link :to="{ name: 'companyBranch', params: { companyId: branch.company.id, id: branch.id } }"
             class="branch">
  {{ branch.name }}
</router-link>
</template>

<script>
export default {
    props: ['branch']
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.branch {
    display: flex;
    padding: $s-button-padding;
    border-bottom: 1px solid transparentize($c-white, 0.5);

    &:last-child {
        margin-bottom: 20px;
    }
}
</style>
