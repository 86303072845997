import '#/app'
import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import router from '@/router'
import store from '@/store'
import Api from '@/api'

sync(store, router)

new Vue({
    el: '#app',
    router,
    store,
    template: '<router-view/>',
    mounted() {
        const loginStatus = this.$store.getters.loginStatus
        if (loginStatus === 'FAILED') {
            this.$store.dispatch('logOut')
        } else if (loginStatus === 'FINISHED') {
            Api.ping(this.$store.getters.session.token)
                .catch(() => this.$store.dispatch('logOut'))
        }
    }
});
