<template>
<div class="bottom-bar" style="display:none">
  <router-link :to="{ name: 'dashboard' }"
               tag="button"
               class="button alt"
               active-class="active">
    <span class="icon home"></span>
  </router-link>
  <div class="back-forward">
    <button class="button alt" @click="$emit('clickedBackButton')">
      <span class="icon back"></span>
    </button>
    <button class="button alt">
      <span class="icon forward"></span>
    </button>
  </div>
</div>
</template>

<script>
export default {
    name: 'navigation-bar',
    props: ['selected']
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.bottom-bar {
    display: flex;
    justify-content: space-between;
    height: 66px;
    width: 100%;
    z-index: 1030;

    .button {
        padding: 0 20px;
        margin: 10px;

        .icon {
            width: 24px;
            height: 24px;
            font-size: 22px;
            display: block;
        }

        .icon.home {
            background: url(images/home.png) center/contain no-repeat;
        }

        .icon.back {
            background: url(images/back.png) center/contain no-repeat;
        }

        .icon.forward {
            background: url(images/forward.png) center/contain no-repeat;
        }
    }

    .back-forward {
        display: flex;

        .button {
            padding: 0 30px;
        }
    }
}
</style>
