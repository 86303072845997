var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "company",
      attrs: { to: { name: "company", params: { id: _vm.company.id } } }
    },
    [_vm._v("\n  " + _vm._s(_vm.company.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }