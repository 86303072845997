var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _vm.loadingState === "loading"
        ? _c("content-block", { key: "loading" }, [_c("loading-indicator")], 1)
        : _vm.loadingState === "failed"
        ? _c(
            "content-block",
            { key: "failed" },
            [
              _c("error-indicator", {
                attrs: {
                  message:
                    "Er is iets foutgegaan bij het ophalen van de gegevens van deze proefrit. Probeer het opnieuw."
                }
              })
            ],
            1
          )
        : _vm.loadingState === "done" && _vm.testDrive
        ? _c("content-block", {
            key: "testdrive",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("div", { staticClass: "summary" }, [
                      !_vm.testDrive.finishedAt
                        ? _c("h2", [
                            _vm._v(
                              "Proefrit vanaf " +
                                _vm._s(
                                  _vm.formattedTime(_vm.testDrive.createdAt)
                                )
                            )
                          ])
                        : _c("h2", [
                            _vm._v(
                              "Proefrit van " +
                                _vm._s(
                                  _vm.formattedTime(_vm.testDrive.createdAt)
                                ) +
                                " tot " +
                                _vm._s(
                                  _vm.formattedTime(_vm.testDrive.finishedAt)
                                )
                            )
                          ]),
                      _vm._v(" "),
                      _vm.testDrive.car.photo
                        ? _c("img", {
                            staticClass: "photo",
                            attrs: { src: _vm.testDrive.car.photo }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Datum")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(
                            _vm._s(_vm.formattedDate(_vm.testDrive.createdAt))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Voornaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.testDrive.customer.firstName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Achternaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.testDrive.customer.lastName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("E-mailadres")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.testDrive.customer.email))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Telefoonnummer")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.testDrive.customer.phoneNumber))
                        ])
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    !_vm.testDrive.finishedAt
                      ? _c(
                          "button",
                          {
                            staticClass: "content__actions__action",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.finishTestDrive()
                              }
                            }
                          },
                          [_vm._v("\n        Proefrit afronden\n      ")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "content__actions__action",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.sendToCalculator()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n        Afgerond. Bereken leaseprijs\n      "
                            )
                          ]
                        )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.loadingState === "done"
        ? _c("content-block", {
            key: "notfound",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [_vm._v("\n      Proefrit niet gevonden\n    ")]
                },
                proxy: true
              }
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }