export function formattedPrice(euros) {
    return '€ ' + euros.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function formattedTime(str) {
    if (!str) return ''
    const date = new Date(str)
    return date.toLocaleString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit',
    })
}

export function formattedDate(str) {
    if (!str) return ''
    const date = new Date(str)
    return date.toLocaleString('nl-NL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}
