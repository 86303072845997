var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _c("content-block", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.loadingState === "loading"
                  ? _c("loading-indicator")
                  : _vm.loadingState === "failed"
                  ? _c("error-indicator", {
                      attrs: {
                        message:
                          "Er is iets foutgegaan bij het ophalen van de gebruikers. Probeer het opnieuw."
                      }
                    })
                  : _vm.loadingState === "done"
                  ? _c("div", { staticClass: "scrollable" }, [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.user.name)),
                        _c("br"),
                        _vm._v("van vestiging " + _vm._s(_vm.user.branch.name)),
                        _c("br"),
                        _vm._v("van " + _vm._s(_vm.user.branch.company.name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary" }, [
                        _c("div", { staticClass: "summary__spec" }, [
                          _c("div", { staticClass: "summary__spec__name" }, [
                            _vm._v("Naam")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summary__spec__value" }, [
                            _vm._v(_vm._s(_vm.user.name))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.user.telnr
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Telefoonnummer")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v(_vm._s(_vm.user.telnr))]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec" }, [
                          _c("div", { staticClass: "summary__spec__name" }, [
                            _vm._v("E-mailadres")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summary__spec__value" }, [
                            _vm._v(_vm._s(_vm.user.email))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.user.isAdministrator
                          ? _c("div", { staticClass: "summary__spec" }, [
                              _c(
                                "div",
                                { staticClass: "summary__spec__name" },
                                [_vm._v("Administrator")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "summary__spec__value" },
                                [_vm._v("Deze gebruiker is een administrator.")]
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "router-link",
                  {
                    staticClass: "content__actions__action",
                    attrs: {
                      to: {
                        name: "editCompanyBranchUser",
                        params: {
                          companyId: _vm.companyId,
                          branchId: _vm.branchId,
                          id: _vm.id
                        }
                      }
                    }
                  },
                  [_vm._v("\n        Gebruiker bewerken\n      ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }