var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "user",
      attrs: {
        to: {
          name: "companyBranchUser",
          params: {
            companyId: _vm.user.branch.company.id,
            branchId: _vm.user.branch.id,
            id: _vm.user.id
          }
        }
      }
    },
    [_vm._v("\n  " + _vm._s(_vm.user.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }