<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van de gebruikers. Probeer het opnieuw."/>
      <div v-else-if="loadingState === 'done'" class="scrollable">
        <h3>{{ user.name }}<br>van vestiging {{ user.branch.name }}<br>van {{ user.branch.company.name }}</h3>
        <div class="summary">
          <div class="summary__spec">
            <div class="summary__spec__name">Naam</div>
            <div class="summary__spec__value">{{ user.name }}</div>
          </div>
          <div class="summary__spec" v-if="user.telnr">
            <div class="summary__spec__name">Telefoonnummer</div>
            <div class="summary__spec__value">{{ user.telnr }}</div>
          </div>
          <div class="summary__spec">
            <div class="summary__spec__name">E-mailadres</div>
            <div class="summary__spec__value">{{ user.email }}</div>
          </div>
          <div class="summary__spec" v-if="user.isAdministrator">
            <div class="summary__spec__name">Administrator</div>
            <div class="summary__spec__value">Deze gebruiker is een administrator.</div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'editCompanyBranchUser', params: { companyId: companyId, branchId: branchId, id: id } }"
                   class="content__actions__action">
        Gebruiker bewerken
      </router-link>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import UserInList from '@/components/UserInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        UserInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    props: ['companyId', 'branchId', 'id'],
    data: () => ({
        user: [],
        loadingState: 'none',
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'companyBranch', params: { companyId: this.companyId, id: this.branchId } })
        },
        loadUser() {
            this.loadingState = 'loading'
            Api.getCompanyBranchUser(this.session.token, this.companyId, this.branchId, this.id)
                .then(data => {
                    this.user = data.user
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        },
    },
    mounted() {
        this.loadUser()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.no-users-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>
