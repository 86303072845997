var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("dashboard-bar", { on: { clickedBackButton: _vm.onClickBackButton } }),
      _vm._v(" "),
      _vm.step === "CAR"
        ? _c("content-block", {
            key: "CAR",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "carform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v("Gegevens auto")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.licensePlate,
                              expression: "licensePlate"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "licensePlate",
                            maxlength: "16",
                            pattern: "^[a-zA-Z0-9-]+$",
                            placeholder: "Kenteken",
                            required: ""
                          },
                          domProps: { value: _vm.licensePlate },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.licensePlate = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.dealerLicensePlate,
                              expression: "dealerLicensePlate"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "dealerLicensePlate",
                            maxlength: "16",
                            pattern: "^[a-zA-Z0-9-]+$",
                            placeholder: "Handelaarskenteken (optioneel)"
                          },
                          domProps: { value: _vm.dealerLicensePlate },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.dealerLicensePlate = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "photo-uploader",
                          {
                            model: {
                              value: _vm.photo,
                              callback: function($$v) {
                                _vm.photo = $$v
                              },
                              expression: "photo"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "activator" },
                                slot: "activator"
                              },
                              [
                                !_vm.photo
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        { staticClass: "button in-content" },
                                        [_vm._v("Voeg een foto toe")]
                                      )
                                    ])
                                  : _c("div", { staticClass: "photo" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.photo.imageURL,
                                          alt: "photo"
                                        }
                                      })
                                    ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { form: "carform" }
                      },
                      [_vm._v("Volgende")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.step === "CUSTOMER"
        ? _c("content-block", {
            key: "CUSTOMER",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        attrs: { id: "customerform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.nextStep()
                          }
                        }
                      },
                      [
                        _c("h3", [_vm._v("Gegevens klant")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstName,
                              expression: "firstName"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "firstName",
                            maxlength: "128",
                            placeholder: "Voornaam",
                            required: ""
                          },
                          domProps: { value: _vm.firstName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.firstName = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lastName,
                              expression: "lastName"
                            }
                          ],
                          attrs: {
                            type: "text",
                            name: "lastName",
                            placeholder: "Achternaam",
                            maxlength: "128",
                            required: ""
                          },
                          domProps: { value: _vm.lastName },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lastName = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          attrs: {
                            type: "email",
                            name: "email",
                            maxlength: "255",
                            placeholder: "E-mailadres",
                            required: ""
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone"
                            }
                          ],
                          attrs: {
                            type: "phone",
                            name: "phone",
                            maxlength: "64",
                            pattern: "^\\+?[0-9 -]+$",
                            placeholder: "Telefoonnummer",
                            required: ""
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { form: "customerform" }
                      },
                      [_vm._v("Volgende")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.step === "CONFIRM"
        ? _c("content-block", {
            key: "CONFIRM",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("h3", [_vm._v("Bevestig de ingevulde gegevens")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary" }, [
                      _c("h4", { staticClass: "summary__title" }, [
                        _vm._v("Het voertuig")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Kenteken")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.licensePlate))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Handelaarskenteken")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.dealerLicensePlate))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Afbeelding")
                        ]),
                        _vm._v(" "),
                        _vm.photo
                          ? _c("img", {
                              staticClass: "summary__spec__value",
                              attrs: { src: _vm.photo.imageURL }
                            })
                          : _c("div", { staticClass: "summary__spec__value" }, [
                              _vm._v("Geen afbeelding toegevoegd")
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary" }, [
                      _c("h4", { staticClass: "summary__title" }, [
                        _vm._v("De klant")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Voornaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.firstName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Achternaam")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.lastName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("E-mailadres")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.email))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary__spec" }, [
                        _c("div", { staticClass: "summary__spec__name" }, [
                          _vm._v("Telefoonnummer")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "summary__spec__value" }, [
                          _vm._v(_vm._s(_vm.phone))
                        ])
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "content__actions__action",
                        attrs: { disabled: _vm.saving },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.saveTestDrive()
                          }
                        }
                      },
                      [
                        _vm.saving
                          ? _c("loading-indicator", {
                              attrs: { type: "inline" }
                            })
                          : _vm._e(),
                        _vm._v(" Proefrit starten\n      ")
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _c("navigation-bar", { on: { clickedBackButton: _vm.onClickBackButton } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }