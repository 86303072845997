var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loading-indicator",
      class: { inline: _vm.type === "inline", block: _vm.type === "block" }
    },
    [
      _c("div", {
        staticClass: "spinner",
        class: { inline: _vm.type === "inline", block: _vm.type === "block" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }