<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block>
    <template v-slot:default>
      <loading-indicator v-if="loadingState === 'loading'"/>
      <error-indicator v-else-if="loadingState === 'failed'"
                       message="Er is iets foutgegaan bij het ophalen van je proefritten. Probeer het opnieuw."/>
      <div v-else-if="testDrives.length > 0" class="testdrives scrollable">
        <h3>Geregistreerde proefritten</h3>
        <test-drive-in-list v-for="td in testDrives"
                            :key="td.id"
                            :testdrive="td"/>
        <div class="deletion-notice">
          Afgeronde proefritten worden na 7 dagen verwijderd
        </div>
      </div>
      <div v-else class="no-testdrives-indicator">
        Geen proefritten gevonden
      </div>
    </template>
    <template v-slot:actions>
      <router-link :to="{ name: 'newTestDrive' }" class="create content__actions__action">Registreer nieuwe proefrit</router-link>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import TestDriveInList from '@/components/TestDriveInList.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ErrorIndicator from '@/components/ErrorIndicator.vue'
import Api from '@/api'

export default {
    components: {
        DashboardBar,
        NavigationBar,
        TestDriveInList,
        ContentBlock,
        LoadingIndicator,
        ErrorIndicator
    },
    data: () => ({
        testDrives: [],
        loadingState: 'none'
    }),
    computed: mapGetters([
        'session'
    ]),
    methods: {
        onClickBackButton() {
            this.$router.push({ name: 'dashboard' })
        },
        loadTestDrives() {
            this.loadingState = 'loading'
            Api.listTestDrives(this.session.token)
                .then(data => {
                    this.testDrives = data.testDrives,
                    this.loadingState = 'done'
                })
                .catch(() => this.loadingState = 'failed')
        }
    },
    mounted() {
        this.loadTestDrives()
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

h3 {
    padding: 20px;
}

.no-testdrives-indicator {
    color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.deletion-notice {
    padding: $s-button-padding;
    color: $c-white;
}
</style>
