var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-bar" },
    [
      _vm.$listeners && _vm.$listeners.clickedBackButton
        ? _c("button", {
            staticClass: "button back",
            on: {
              click: function($event) {
                return _vm.$emit("clickedBackButton")
              }
            }
          })
        : _c("div", { staticClass: "button placeholder" }),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "logo-link", attrs: { to: { name: "dashboard" } } },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("images/interlease-logo.png") }
          })
        ]
      ),
      _vm._v(" "),
      _c("router-link", {
        staticClass: "button account",
        attrs: {
          to: { name: "account" },
          "active-class": "active",
          tag: "button"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }