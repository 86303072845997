<template>
<router-link :to="{ name: 'companyBranchUser', params: { companyId: user.branch.company.id, branchId: user.branch.id, id: user.id } }"
             class="user">
  {{ user.name }}
</router-link>
</template>

<script>
export default {
    props: ['user']
}
</script>

<style lang="scss" scoped>
@import '~#/variables';

.user {
    display: flex;
    padding: $s-button-padding;
    border-bottom: 1px solid transparentize($c-white, 0.5);

    &:last-child {
        margin-bottom: 20px;
    }
}
</style>
