<template>
<div class="page">
  <dashboard-bar @clickedBackButton="onClickBackButton"/>
  <content-block key="CAR" v-if="step === 'CAR'">
    <template v-slot:default>
      <form @submit.prevent="nextStep()" id="carform" class="form">
        <h3>Gegevens auto</h3>
        <input type="text" name="licensePlate" v-model="licensePlate"
               maxlength="16" pattern="^[a-zA-Z0-9-]+$"
               placeholder="Kenteken"
               required/>
        <input type="text" name="dealerLicensePlate" v-model="dealerLicensePlate"
               maxlength="16" pattern="^[a-zA-Z0-9-]+$"
               placeholder="Handelaarskenteken (optioneel)" />
        <photo-uploader v-model="photo">
          <div slot="activator">
            <div v-if="!photo">
              <a class="button in-content">Voeg een foto toe</a>
            </div>
            <div v-else class="photo">
              <img :src="photo.imageURL" alt="photo">
            </div>
          </div>
        </photo-uploader>
      </form>
    </template>
    <template v-slot:actions>
      <button form="carform" class="content__actions__action">Volgende</button>
    </template>
  </content-block>
  <content-block key="CUSTOMER" v-else-if="step === 'CUSTOMER'">
    <template v-slot:default>
      <form @submit.prevent="nextStep()" id="customerform" class="form">
        <h3>Gegevens klant</h3>
        <input type="text" name="firstName" v-model="firstName"
               maxlength="128"
               placeholder="Voornaam"
               required/>
        <input type="text" name="lastName" v-model="lastName"
               placeholder="Achternaam"
               maxlength="128"
               required/>
        <input type="email" name="email" v-model="email"
               maxlength="255"
               placeholder="E-mailadres"
               required/>
        <input type="phone" name="phone" v-model="phone"
               maxlength="64" pattern="^\+?[0-9 -]+$"
               placeholder="Telefoonnummer"
               required/>
      </form>
    </template>
    <template v-slot:actions>
      <button form="customerform" class="content__actions__action">Volgende</button>
    </template>
  </content-block>
  <content-block key="CONFIRM" v-else-if="step === 'CONFIRM'">
    <template v-slot:default>
      <h3>Bevestig de ingevulde gegevens</h3>
      <div class="summary">
        <h4 class="summary__title">Het voertuig</h4>
        <div class="summary__spec">
          <div class="summary__spec__name">Kenteken</div>
          <div class="summary__spec__value">{{ licensePlate }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Handelaarskenteken</div>
          <div class="summary__spec__value">{{ dealerLicensePlate }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Afbeelding</div>
          <img v-if="photo" class="summary__spec__value" :src="photo.imageURL" />
          <div v-else class="summary__spec__value">Geen afbeelding toegevoegd</div>
        </div>
      </div>
      <div class="summary">
        <h4 class="summary__title">De klant</h4>
        <div class="summary__spec">
          <div class="summary__spec__name">Voornaam</div>
          <div class="summary__spec__value">{{ firstName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Achternaam</div>
          <div class="summary__spec__value">{{ lastName }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">E-mailadres</div>
          <div class="summary__spec__value">{{ email }}</div>
        </div>
        <div class="summary__spec">
          <div class="summary__spec__name">Telefoonnummer</div>
          <div class="summary__spec__value">{{ phone }}</div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <button :disabled="saving"
              @click.prevent="saveTestDrive()"
              class="content__actions__action">
        <loading-indicator type="inline" v-if="saving" /> Proefrit starten
      </button>
    </template>
  </content-block>
  <navigation-bar @clickedBackButton="onClickBackButton"/>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardBar from '@/components/DashboardBar.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import PhotoUploader from '@/components/PhotoUploader.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import Api from '@/api'

const STEPS = ["CAR", "CUSTOMER", "CONFIRM"]

export default{
    data: () => ({
        step: STEPS[0],
        photo: null,
        licensePlate: '',
        dealerLicensePlate: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        saving: false
        
    }),
    computed: mapGetters([
        'session'
    ]),
    components: {
        DashboardBar,
        NavigationBar,
        PhotoUploader,
        ContentBlock,
        LoadingIndicator
    },
    methods: {
        onClickBackButton() {
            if(this.step === "CAR") {
                this.$router.push({ name: 'testDriveList' })
            }
            else if(this.step === "CUSTOMER") {
                this.step = "CAR"
            }
            else if(this.step === "CONFIRM") {
                this.step = "CUSTOMER"
            }
        },
        nextStep() {
            if(this.step === "CAR") {
                this.step = 'CUSTOMER'
            }
            else if(this.step === "CUSTOMER") {
                this.step = "CONFIRM"
            }
        },
        saveTestDrive() {
            this.saving = true

            // First we need to fetch the actual image into a base64 data URL
            const finish = (imageUrl) => {
                const payload = {
                    car: {
                        licensePlate: this.licensePlate,
                        dealerLicensePlate: this.dealerLicensePlate || null,
                        photo: imageUrl
                    },
                    customer: {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        phoneNumber: this.phone
                    }
                }
                Api.createTestDrive(this.session.token, payload)
                    .then(data => this.$router.push({ name: 'testDriveList' }))
                    .catch(() => {
                        this.saving = false
                        window.alert('Er is iets foutgegaan bij het opslaan van de proefrit. Probeer het opnieuw.')
                    })
            }

            if (this.photo) {
                Api.readBlob(this.photo.imageURL)
                    .then(blob => {
                        const reader = new FileReader();
                        reader.addEventListener('load', () => {
                            finish(reader.result)
                        })
                        reader.readAsDataURL(blob)
                });
            } else {
                finish(null)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~#/variables';
</style>
